module.exports = [
  {
    id: 'activecampaign',
    label: 'ActiveCampaign',
    description:
      'Automatically segment quiz takers into targeted email campaigns for personalized follow-ups.',
    primaryColor: '#004cfe',
    link: 'https://www.activecampaign.com/apps/interact-integration',
    help: 'https://help.tryinteract.com/en/articles/894469-how-to-connect-activecampaign-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'add-to-contact-list',
        label: 'Add to Contact List',
        description: 'Adds a contact to a specified list',
      },
      {
        id: 'add-to-automation',
        label: 'Add to Automation',
        description: 'Enrolls a contact in a selected automation',
      },
      {
        id: 'update-contact-field',
        label: 'Update Contact Field',
        description: 'Updates a custom field for a contact',
      },
      {
        id: 'add-tag-to-contact',
        label: 'Add Tag to Contact',
        description: 'Applies a tag to a contact',
      },
    ],
  },
  {
    id: 'acton',
    label: 'Act-On',
    description:
      'Transform quiz results into actionable lead data for tailored marketing strategies.',
    primaryColor: '#023c5b',
    help: 'https://help.tryinteract.com/en/articles/897713-how-to-connect-act-on-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'add-to-contact-list',
        label: 'Add to Contact List',
        description: 'Adds a contact to a specified list',
      },
      {
        id: 'update-contact-list-column',
        label: 'Update Contact List Column',
        description: 'Updates a column value for a contact in a list',
      },
    ],
  },
  {
    id: 'aweber',
    label: 'AWeber',
    description:
      'Seamlessly add quiz participants to email lists and trigger customized autoresponders.',
    primaryColor: '#256be8',
    link: 'https://www.aweber.com/integrations/lead-generation/interact/',
    help: 'https://help.tryinteract.com/en/articles/898329-how-to-connect-aweber-to-interact',
    category: 'email',
    actions: [
      {
        id: 'add-to-list',
        label: 'Add to List',
        description: 'Adds a subscriber to a specified list',
      },
      {
        id: 'update-contact-field',
        label: 'Update Contact Field',
        description: 'Updates a custom field for a subscriber',
      },
      {
        id: 'add-tag-to-contact',
        label: 'Add Tag to Contact',
        description: 'Applies a tag to a subscriber',
      },
    ],
  },
  {
    id: 'beehiiv',
    label: 'beehiiv',
    description:
      'Automatically subscribe quiz leads to your beehiiv publication and trigger personalized autoresponders.',
    primaryColor: '#FF5EC4',
    help: 'https://help.tryinteract.com/en/articles/10750244-how-to-connect-beehiiv-to-interact',
    category: 'email',
    actions: [
      {
        id: 'add-to-publication',
        label: 'Add to Publication',
        description: 'Adds a subscriber to a specified publication',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a subscriber',
      },
      {
        id: 'add-to-automation',
        label: 'Add to Automation',
        description: 'Enrolls a subscriber in a specific automation',
      },
    ],
  },
  {
    id: 'campaign-monitor',
    label: 'Campaign Monitor',
    description:
      'Convert quiz engagement into personalized email journeys for enhanced customer experiences.',
    primaryColor: '#7d5dfe',
    help: 'https://help.tryinteract.com/en/articles/898509-how-to-connect-campaign-monitor-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'add-to-contact-list',
        label: 'Add to Contact List',
        description: 'Adds a subscriber to a specified list',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a subscriber',
      },
    ],
  },
  {
    id: 'clickfunnels',
    label: 'ClickFunnels',
    description: 'Funnel quiz leads directly into high-converting sales and marketing funnels.',
    primaryColor: '#3c9bd6',
    link: 'https://zapier.com/apps/clickfunnels/integrations/interact/422688/add-new-interact-quiz-leads-to-clickfunnels-lists',
    help: 'https://help.tryinteract.com/en/articles/912300-how-to-connect-zapier-to-interact',
    category: 'automation',
    zapier: true,
    actions: [
      {
        id: 'add-new-interact-quiz-leads-to-clickfunnels-classic-lists',
        label: 'Add new Interact Quiz leads to ClickFunnels Classic lists',
        description: 'Adds a new lead to a specified list in ClickFunnels',
      },
    ],
  },
  {
    id: 'constant-contact',
    label: 'Constant Contact',
    description:
      'Instantly grow your email list with quiz participants and kickstart targeted campaigns.',
    primaryColor: '#ffa122',
    link: 'https://marketplace.constantcontact.com/Listing/applications/interact/PML-44668',
    help: 'https://help.tryinteract.com/en/articles/898613-how-to-connect-constant-contact-to-interact',
    category: 'email',
    actions: [
      {
        id: 'add-to-email-list',
        label: 'Add to Email List',
        description: 'Adds a contact to a specified email list',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a contact',
      },
    ],
  },
  {
    id: 'convertkit',
    label: 'Kit',
    description:
      'Automatically enroll quiz takers in relevant email sequences based on their results.',
    primaryColor: '#fb6e74',
    help: 'https://help.tryinteract.com/en/articles/900579-how-to-connect-convertkit-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'add-to-sequence',
        label: 'Add to Sequence',
        description: 'Enrolls a subscriber in a specific sequence',
      },
      {
        id: 'add-to-form',
        label: 'Add to Form',
        description: 'Adds a subscriber to a specified form',
      },
      {
        id: 'update-subscriber-form',
        label: 'Update Subscriber Form',
        description: 'Updates form fields for a subscriber',
      },
    ],
  },
  {
    id: 'csvfile',
    label: '.csv File Download',
    active: false,
  },
  {
    id: 'dotdigital',
    label: 'dotdigital',
    description:
      'Leverage quiz data to power multi-channel marketing campaigns across email and SMS.',
    primaryColor: '#7cc142',
    help: 'https://help.tryinteract.com/en/articles/1405387-how-to-connect-dotmailer-to-interact',
    category: 'email',
    actions: [
      {
        id: 'send-campaign',
        label: 'Send Campaign',
        description: 'Triggers a campaign for a contact',
      },
      {
        id: 'update-contact-data-field',
        label: 'Update Contact Data Field',
        description: 'Updates a data field for a contact',
      },
    ],
  },
  {
    id: 'drip',
    label: 'Drip',
    description: 'Use quiz insights to fuel personalized ecommerce marketing automations at scale.',
    primaryColor: '#f223f2',
    link: 'https://www.drip.com/integrations/interact',
    help: 'https://help.tryinteract.com/en/articles/901563-how-to-connect-drip-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'add-to-campaign',
        label: 'Add to Campaign',
        description: 'Subscribes someone to a campaign',
      },
      {
        id: 'update-subscriber-custom-field',
        label: 'Update Subscriber Custom Field',
        description: 'Updates a custom field for a subscriber',
      },
      {
        id: 'add-tag-to-contact',
        label: 'Add Tag to Contact',
        description: 'Adds a tag to a subscriber',
      },
      {
        id: 'send-custom-event',
        label: 'Send Custom Event',
        description: 'Creates an event for a subscriber',
      },
    ],
  },
  {
    id: 'eloqua',
    label: 'Eloqua',
    active: false,
  },
  {
    id: 'facebook-pixel',
    label: 'Facebook Pixel',
    description:
      'Track quiz interactions to create custom audiences and optimize ad targeting on Facebook.',
    help: 'https://help.tryinteract.com/en/articles/4143729-facebook-pixel-setup-for-tracking-leads-and-creating-audiences',
    category: 'analytics',
    actions: [
      {
        id: 'send-event-on-quiz-view',
        label: 'Send Event On Quiz View',
        description: 'Sends an event when a quiz is viewed',
      },
      {
        id: 'send-event-on-quiz-start',
        label: 'Send Event On Quiz Start',
        description: 'Sends an event when a quiz starts',
      },
      {
        id: 'send-event-on-question-answer',
        label: 'Send Event On Question Answer',
        description: 'Sends an event when a question is answered',
      },
      {
        id: 'send-event-on-lead-submitted',
        label: 'Send Event On Lead Submitted',
        description: 'Sends an event when a lead is submitted',
      },
    ],
    active: false,
  },
  {
    id: 'flodesk',
    label: 'Flodesk',
    description:
      'Transform quiz takers into subscribers with beautiful, design-focused email workflows.',
    primaryColor: '#241a17',
    link: 'https://app.flodesk.com/account/integration',
    help: 'https://help.tryinteract.com/en/articles/5221453-how-to-connect-interact-to-flodesk',
    category: 'email',
    actions: [
      {
        id: 'add-to-sequence',
        label: 'Add to Sequence',
        description: 'Enrolls a subscriber in a specific sequence',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a subscriber',
      },
    ],
  },
  {
    id: 'getresponse',
    label: 'GetResponse',
    description:
      'Automatically funnel quiz leads into targeted autoresponders and marketing automation.',
    primaryColor: '#13b1f0',
    link: 'https://www.getresponse.com/integrations/interact.html',
    help: 'https://help.tryinteract.com/en/articles/914535-how-to-connect-your-getresponse-account-to-interact',
    category: 'email',
    actions: [
      {
        id: 'add-to-campaign',
        label: 'Add to Campaign',
        description: 'Adds a contact to a specific campaign',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a contact',
      },
    ],
  },
  {
    id: 'gmail',
    label: 'Gmail',
    description:
      'Send personalized email notifications for each new quiz submission directly from Gmail.',
    primaryColor: '#eb4a3d',
    link: 'https://zapier.com/apps/gmail/integrations/interact/9468/send-gmail-email-notifications-from-new-interact-entries',
    help: 'https://help.tryinteract.com/en/articles/912300-how-to-connect-zapier-to-interact',
    category: 'email',
    zapier: true,
    actions: [
      {
        id: 'send-gmail-email-notifications-from-new-interact-entries',
        label: 'Send Gmail email notifications from new Interact entries',
        description: 'Sends an email notification from a new Interact entry',
      },
    ],
  },
  {
    id: 'google-analytics',
    label: 'Google Analytics',
    description: 'Track quiz performance and user behavior to optimize your marketing funnel.',
    primaryColor: '#ffaa10',
    help: 'https://help.tryinteract.com/en/articles/923741-how-to-connect-google-analytics-to-interact',
    category: 'analytics',
    actions: [
      {
        id: 'send-event-on-quiz-view',
        label: 'Send Event On Quiz View',
        description: 'Sends an event when a quiz is viewed',
      },
      {
        id: 'send-event-on-quiz-start',
        label: 'Send Event On Quiz Start',
        description: 'Sends an event when a quiz starts',
      },
      {
        id: 'send-event-on-question-answer',
        label: 'Send Event On Question Answer',
        description: 'Sends an event when a question is answered',
      },
      {
        id: 'send-event-on-lead-submitted',
        label: 'Send Event On Form Submission',
        description: 'Sends an event when a form is submitted',
      },
    ],
  },
  {
    id: 'google-sheets',
    label: 'Google Sheets',
    description:
      'Automatically log quiz responses in spreadsheets for easy analysis and reporting.',
    primaryColor: '#119e59',
    link: 'https://zapier.com/apps/google-sheets/integrations/interact/177446/create-google-sheet-rows-for-new-leads-in-interact',
    help: 'https://help.tryinteract.com/en/articles/912300-how-to-connect-zapier-to-interact',
    category: 'email',
    zapier: true,
    actions: [
      {
        id: 'create-google-sheet-rows-for-new-leads-in-interact',
        label: 'Create Google Sheet rows for new leads in Interact',
        description: 'Creates a new row in a Google Sheet for a new lead',
      },
    ],
  },
  {
    id: 'hubspot',
    label: 'HubSpot',
    description:
      'Seamlessly integrate quiz data into your CRM for enhanced lead scoring and nurturing.',
    primaryColor: '#ff7a59',
    link: 'https://ecosystem.hubspot.com/marketplace/apps/marketing/lead-generation/interact',
    help: 'https://help.tryinteract.com/en/articles/901912-how-to-connect-hubspot-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'add-to-contact-list',
        label: 'Add to Contact List',
        description: 'Adds a contact to a specified list',
      },
      {
        id: 'add-to-workflow',
        label: 'Add to Workflow',
        description: 'Enrolls a contact in a specific workflow',
      },
      {
        id: 'update-contact-property',
        label: 'Update Contact Property',
        description: 'Updates a property for a contact',
      },
    ],
  },
  {
    id: 'infusionsoft',
    label: 'Keap (formerly Infusionsoft)',
    description: 'Trigger personalized marketing campaigns based on quiz responses and results.',
    primaryColor: '#37a635',
    link: 'https://marketplace.infusionsoft.com/listing/interact-interact',
    help: 'https://help.tryinteract.com/en/articles/904417-how-to-connect-infusionsoft-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'run-action-set',
        label: 'Run Action Set',
        description: 'Executes an action set for a contact',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a contact',
      },
      {
        id: 'add-tag-to-contact',
        label: 'Add Tag to Contact',
        description: 'Applies a tag to a contact',
      },
    ],
  },
  {
    id: 'interact',
    label: 'Interact',
    active: false,
  },
  {
    id: 'kajabi',
    label: 'Kajabi',
    description:
      'Streamline lead generation by connecting quiz takers to your online courses and memberships.',
    primaryColor: '#2e91fc',
    link: 'https://zapier.com/apps/interact/integrations/kajabi/152480/create-kajabi-form-submissions-for-new-interact-leads',
    help: 'https://help.tryinteract.com/en/articles/2435599-how-to-connect-interact-to-kajabi',
    category: 'membership',
    zapier: true,
    actions: [
      {
        id: 'create-kajabi-form-submissions-for-new-interact-leads',
        label: 'Create Kajabi form submissions for new Interact leads',
        description: 'Creates a new form submission in Kajabi for a new lead',
      },
    ],
  },
  {
    id: 'kartra',
    label: 'Kartra',
    description: 'Automate lead capture and segmentation for your all-in-one marketing platform.',
    primaryColor: '#5fc3e1',
    help: 'https://help.tryinteract.com/en/articles/2875997-how-to-connect-kartra-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'add-to-list',
        label: 'Add to List',
        description: 'Adds a lead to a specified list',
      },
      {
        id: 'update-contact-field',
        label: 'Update Contact Field',
        description: 'Updates a custom field for a lead',
      },
      {
        id: 'add-tag-to-contact',
        label: 'Add Tag to Contact',
        description: 'Applies a tag to a lead',
      },
    ],
  },
  {
    id: 'klaviyo',
    label: 'Klaviyo',
    description: 'Power data-driven email and SMS campaigns with rich quiz participant insights.',
    primaryColor: '#24ce7a',
    link: 'https://connect.klaviyo.com/integrations/interact-the-quiz-collective-inc',
    help: 'https://help.tryinteract.com/en/articles/905182-how-to-connect-klaviyo-to-interact',
    category: 'email',
    actions: [
      {
        id: 'add-to-contact-list',
        label: 'Add to Contact List',
        description: 'Adds a profile to a specified list',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom property for a profile',
      },
    ],
  },
  {
    id: 'mad-mimi',
    label: 'Mad Mimi',
    description:
      'Simplify email list growth by automatically adding quiz takers to targeted lists.',
    primaryColor: '#fb2136',
    link: 'https://madmimi.com/integrations/interact',
    help: 'https://help.tryinteract.com/en/articles/905342-how-to-connect-mad-mimi-to-interact',
    category: 'email',
    actions: [
      {
        id: 'add-to-contact-list',
        label: 'Add to Contact List',
        description: 'Adds a contact to a specified list',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a contact',
      },
    ],
  },
  {
    id: 'mailchimp',
    label: 'Mailchimp',
    description:
      'Effortlessly grow and segment your email list based on quiz responses and results.',
    primaryColor: '#000000',
    link: 'https://mailchimp.com/en/integrations/interact/',
    help: 'https://help.tryinteract.com/en/articles/905268-how-to-connect-mailchimp-to-interact',
    category: 'email',
    actions: [
      {
        id: 'add-to-list',
        label: 'Add to List',
        description: 'Adds a subscriber to a specified list',
      },
      {
        id: 'update-merge-field',
        label: 'Update Merge Field',
        description: 'Updates a merge field for a subscriber',
      },
      {
        id: 'add-tag-to-contact',
        label: 'Add Tag to Contact',
        description: 'Applies a tag to a subscriber',
      },
    ],
  },
  {
    id: 'mailerlite',
    label: 'MailerLite',
    description: 'Boost your email marketing by turning quiz takers into engaged subscribers.',
    primaryColor: '#03a155',
    help: 'https://help.tryinteract.com/en/articles/971165-how-to-connect-mailerlite-to-interact',
    category: 'email',
    actions: [
      {
        id: 'add-to-contact-list',
        label: 'Add to Contact List',
        description: 'Adds a subscriber to a specified group',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a subscriber',
      },
    ],
  },
  {
    id: 'marketo',
    label: 'Marketo',
    description:
      'Enhance lead scoring and nurturing by integrating quiz data into your marketing automation.',
    primaryColor: '#706eb3',
    link: 'https://zapier.com/apps/interact/integrations/marketo',
    help: 'https://help.tryinteract.com/en/articles/4374735-how-to-connect-interact-to-marketo',
    category: 'automation',
    zapier: true,
    actions: [
      {
        id: 'create-or-update-marketo-leads-for-new-interact-quiz-leads',
        label: 'Create or Update Marketo leads for new Interact quiz leads',
        description: 'Creates or updates a Marketo lead for a new Interact quiz lead',
      },
    ],
  },
  {
    id: 'maropost',
    label: 'Maropost',
    primaryColor: '#4baade',
    description: 'Fuel your multi-channel marketing efforts with detailed quiz participant data.',
    help: 'https://help.tryinteract.com/en/articles/911042-how-to-connect-maropost-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'add-to-list',
        label: 'Add to List',
        description: 'Adds a contact to a specified list',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a contact',
      },
    ],
  },
  {
    id: 'moosend',
    label: 'Moosend',
    description: 'Automate email personalization and list segmentation based on quiz outcomes.',
    primaryColor: '#e46c5f',
    help: 'https://help.tryinteract.com/en/articles/9027804-how-to-connect-interact-to-moosend',
    category: 'automation',
    actions: [
      {
        id: 'add-to-list',
        label: 'Add to List',
        description: 'Adds a subscriber to a specified list',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a subscriber',
      },
    ],
  },
  {
    id: 'omnisend',
    label: 'Omnisend',
    description:
      'Elevate your ecommerce marketing with quiz-based customer segmentation and automation.',
    primaryColor: '#555c66',
    link: 'https://appmarket.omnisend.com/app-details/65c4e5ed639b45f11fbd1d70',
    help: 'https://help.tryinteract.com/en/articles/4490606-how-to-connect-omnisend-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom property for a contact',
      },
      {
        id: 'add-tag-to-contact',
        label: 'Add Tag to Contact',
        description: 'Applies a tag to a contact',
      },
    ],
  },
  {
    id: 'ontraport',
    label: 'Ontraport',
    description:
      'Streamline your sales and marketing processes by integrating quiz data into your CRM.',
    primaryColor: '#1485fe',
    link: 'https://ontraport.com/integrations/interact',
    help: 'https://help.tryinteract.com/en/articles/911157-how-to-connect-ontraport-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'add-to-sequence',
        label: 'Add to Sequence',
        description: 'Adds a contact to a specified sequence',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a contact',
      },
      {
        id: 'add-tag-to-contact',
        label: 'Add Tag to Contact',
        description: 'Applies a tag to a contact',
      },
    ],
  },
  {
    id: 'pardot',
    label: 'Pardot',
    description: 'Enhance B2B lead generation and scoring with detailed quiz response data.',
    primaryColor: '#049bda',
    link: 'https://zapier.com/apps/interact/integrations/pardot',
    help: 'https://help.tryinteract.com/en/articles/911224-how-to-connect-pardot-to-interact',
    category: 'automation',
    zapier: true,
    actions: [
      {
        id: 'create-prospect-in-pardot-for-new-interact-quiz-leads',
        label: 'Create Prospect in Pardot for new Interact quiz leads',
        description: 'Creates a prospect in Pardot for a new Interact quiz lead',
      },
    ],
  },
  {
    id: 'podia',
    label: 'Podia',
    description:
      'Seamlessly funnel quiz participants into your online courses and digital products.',
    primaryColor: '#7558ce',
    link: 'https://zapier.com/apps/interact/integrations/podia/422698/add-new-interact-quiz-leads-to-podia-email-lists',
    help: 'https://help.tryinteract.com/en/articles/8708600-how-to-connect-podia-to-interact',
    category: 'membership',
    zapier: true,
    actions: [
      {
        id: 'add-new-interact-quiz-leads-to-podia-email-lists',
        label: 'Add new Interact quiz leads to Podia email lists',
        description: 'Adds new Interact quiz leads to Podia email lists',
      },
    ],
  },
  {
    id: 'rd-station',
    label: 'RD Station',
    description:
      'Boost your Brazilian market reach by integrating quiz leads into your digital marketing platform.',
    primaryColor: '#19c1ce',
    link: 'https://zapier.com/apps/interact/integrations/rd-station/422701/add-new-rd-station-conversion-events-for-new-interact-quiz-leads',
    help: 'https://help.tryinteract.com/en/articles/912300-how-to-connect-zapier-to-interact',
    category: 'automation',
    zapier: true,
    actions: [
      {
        id: 'add-new-rd-station-conversion-events-for-new-interact-quiz-leads',
        label: 'Add new RD Station conversion events for new Interact quiz leads',
        description: 'Adds new RD Station conversion events for new Interact quiz leads',
      },
    ],
  },
  {
    id: 'sendgrid',
    label: 'SendGrid',
    description:
      'Scale your email deliverability while leveraging quiz data for personalized campaigns.',
    primaryColor: '#009ed9',
    help: 'https://help.tryinteract.com/en/articles/1008760-how-to-connect-sendgrid-to-interact',
    category: 'email',
    actions: [
      {
        id: 'add-to-contact-list',
        label: 'Add to Contact List',
        description: 'Adds a contact to a specified list',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a contact',
      },
    ],
  },
  {
    id: 'sendgrid_legacy',
    label: 'SendGrid Legacy',
    active: false,
  },
  {
    id: 'sharpspring',
    label: 'SharpSpring',
    description: 'Enhance your marketing automation and CRM with rich quiz participant insights.',
    primaryColor: '#84c340',
    link: 'https://zapier.com/apps/interact/integrations/sharpspring/422717/addupdate-sharpspring-leads-for-new-interact-quiz-leads',
    help: 'https://help.tryinteract.com/en/articles/912300-how-to-connect-zapier-to-interact',
    category: 'automation',
    zapier: true,
    actions: [
      {
        id: 'addupdate-sharpspring-leads-for-new-interact-quiz-leads',
        label: 'Add/update SharpSpring leads for new Interact quiz leads',
        description: 'Adds or updates SharpSpring leads for new Interact quiz leads',
      },
    ],
  },
  {
    id: 'simplero',
    label: 'Simplero',
    description:
      'Streamline lead generation for your info products and online courses using quiz data.',
    primaryColor: '#ed1c24',
    help: 'https://help.tryinteract.com/en/articles/3123404-how-to-connect-simplero-to-interact',
    category: 'membership',
    actions: [
      {
        id: 'add-to-list',
        label: 'Add to List',
        description: 'Adds a contact to a specified list',
      },
      {
        id: 'update-custom-field',
        label: 'Update Custom Field',
        description: 'Updates a custom field for a contact',
      },
      {
        id: 'add-tag-to-contact',
        label: 'Add Tag to Contact',
        description: 'Applies a tag to a contact',
      },
    ],
  },
  {
    id: 'slack',
    label: 'Slack',
    description:
      'Get real-time notifications in Slack for new quiz completions and lead generation.',
    primaryColor: '#510f4d',
    link: 'https://zapier.com/apps/interact/integrations/slack/422726/send-slack-notifications-for-new-interact-quiz-leads',
    help: 'https://help.tryinteract.com/en/articles/912300-how-to-connect-zapier-to-interact',
    category: 'communication',
    zapier: true,
    actions: [
      {
        id: 'send-slack-notifications-for-new-interact-quiz-leads',
        label: 'Send Slack notifications for new Interact quiz leads',
        description: 'Sends Slack notifications for new Interact quiz leads',
      },
    ],
  },
  {
    id: 'systemeio',
    label: 'Systeme.io',
    description:
      'Automatically funnel quiz leads into your all-in-one marketing and course platform.',
    primaryColor: '#02a1ff',
    link: 'https://zapier.com/apps/interact/integrations/systemeio',
    help: 'https://help.tryinteract.com/en/articles/8676075-how-to-connect-interact-to-systeme-io',
    category: 'automation',
    zapier: true,
    actions: [
      {
        id: 'create-or-update-systemeio-contacts-with-tags-for-new-interact-leads',
        label: 'Create or update Systeme.io contacts with tags for new Interact leads',
        description: 'Creates or updates Systeme.io contacts with tags for new Interact leads',
      },
      {
        id: 'subscribe-new-interact-leads-to-campaigns-in-systemeio',
        label: 'Subscribe new Interact leads to campaigns in Systeme.io',
        description: 'Subscribes new Interact leads to campaigns in Systeme.io',
      },
    ],
  },
  {
    id: 'teachable',
    label: 'Teachable',
    description:
      'Seamlessly enroll quiz participants into relevant online courses based on their results.',
    primaryColor: '#21cd9c',
    link: 'https://zapier.com/apps/interact/integrations/teachable/422731/enroll-users-in-teachable-courses-from-new-interact-quiz-leads',
    help: 'https://help.tryinteract.com/en/articles/912300-how-to-connect-zapier-to-interact',
    category: 'membership',
    zapier: true,
    actions: [
      {
        id: 'enroll-users-in-teachable-courses-from-new-interact-quiz-leads',
        label: 'Enroll users in Teachable courses from new Interact quiz leads',
        description: 'Enrolls users in Teachable courses from new Interact quiz leads',
      },
    ],
  },
  {
    id: 'thinkific',
    label: 'Thinkific',
    description: 'Customize course sign-ups and recommendations based on quiz responses.',
    primaryColor: '#050347',
    help: 'https://help.tryinteract.com/en/articles/1393590-how-to-connect-thinkific-to-interact',
    category: 'membership',
    actions: [
      {
        id: 'update-custom-sign-up-field',
        label: 'Update Custom Sign Up Field',
        description: 'Updates a custom sign up field for a user',
      },
    ],
  },
  {
    id: 'webhook',
    label: 'Webhook',
    active: false,
    category: 'automation',
  },
  {
    id: 'zapier',
    label: 'Zapier',
    description:
      'Connect Interact Quizzes to thousands of apps, enabling endless automation possibilities.',
    primaryColor: '#ff4a07',
    help: 'https://help.tryinteract.com/en/articles/912300-how-to-connect-zapier-to-interact',
    category: 'automation',
    actions: [
      {
        id: 'trigger-new-quiz-lead',
        label: 'Trigger New Quiz Lead',
        description: 'Sends quiz lead data to a Zapier webhook',
      },
    ],
  },
];
